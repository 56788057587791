import { lazy } from 'react';

// autentication
import RequireAuth from 'layout/RequireAuth';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));

//Tickets
const Tickets = Loadable(lazy(() => import('views/tickets')));
const Ventanilla = Loadable(lazy(() => import('views/ventanilla')));
const Control = Loadable(lazy(() => import('views/control')));
const Empresas = Loadable(lazy(() => import('views/empresas')));
const Reimpresion = Loadable(lazy(() => import('views/reimpresion')));
const LogTickets = Loadable(lazy(() => import('views/log-tickets')));

//SuperAdmin
const SuperAdmin = Loadable(lazy(() => import('views/super-admin')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <RequireAuth />,
    children: [
        {
            path: '/',
            element: <MainLayout />,
            children: [
                {
                    path: '/',
                    element: <Control />
                },
                {
                    path: '/control',
                    element: <Control />
                },
                {
                    path: '/generarTickets',
                    element: <Tickets />
                },
                {
                    path: '/ventanilla',
                    element: <Ventanilla />
                },
                {
                    path: '/logTickets',
                    element: <LogTickets />
                },
                {
                    path: '/tickets/reimpresion',
                    element: <Reimpresion />
                },
                {
                    path: 'dashboard',
                    children: [
                        {
                            path: 'default',
                            element: <Control />
                        }
                    ]
                },
                {
                    path: 'utils',
                    children: [
                        {
                            path: 'util-typography',
                            element: <UtilsTypography />
                        }
                    ]
                },
                {
                    path: 'utils',
                    children: [
                        {
                            path: 'util-color',
                            element: <UtilsColor />
                        }
                    ]
                },
                {
                    path: 'utils',
                    children: [
                        {
                            path: 'util-shadow',
                            element: <UtilsShadow />
                        }
                    ]
                },
                {
                    path: 'icons',
                    children: [
                        {
                            path: 'tabler-icons',
                            element: <UtilsTablerIcons />
                        }
                    ]
                },
                {
                    path: 'icons',
                    children: [
                        {
                            path: 'material-icons',
                            element: <UtilsMaterialIcons />
                        }
                    ]
                },
                {
                    path: 'sample-page',
                    element: <SamplePage />
                },
                {
                    path: '/admin/empresas',
                    element: <Empresas />
                },
                {
                    path: '/superAdmin',
                    element: <SuperAdmin />
                }
            ]
        }
    ]
};

export default MainRoutes;
