// assets
import {
    IconDashboard,
    IconCirclePlus,
    IconBuildingStore,
    IconTicket,
    IconClipboardText,
    IconPrinter,
    IconUserCircle
} from '@tabler/icons';

// constant
const icons = {
    IconDashboard,
    IconCirclePlus,
    IconBuildingStore,
    IconTicket,
    IconClipboardText,
    IconPrinter,
    IconUserCircle
};
// ========================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'group',
    children: [
        {
            id: 'default',
            title: 'Control',
            type: 'item',
            url: '/dashboard/default',
            icon: icons.IconTicket,
            breadcrumbs: false
        },
        {
            id: 'ticket',
            title: 'Generar tickets',
            type: 'item',
            url: '/generarTickets',
            icon: icons.IconCirclePlus,
            breadcrumbs: false
        },
        {
            id: 'ventanilla',
            title: 'Ventanilla',
            type: 'item',
            url: '/ventanilla',
            icon: icons.IconBuildingStore,
            breadcrumbs: false
        },
        {
            id: 'logTickets',
            title: 'Log Tickets',
            type: 'item',
            url: '/logTickets',
            icon: icons.IconClipboardText,
            breadcrumbs: false
        },
        {
            id: 'reimprimir',
            title: 'Reimprimir ticket',
            type: 'item',
            url: '/tickets/reimpresion',
            icon: icons.IconPrinter,
            breadcrumbs: false
        },
        {
            id: 'superadmin',
            title: 'Super Admin',
            type: 'item',
            url: '/superAdmin',
            icon: icons.IconUserCircle,
            breadcrumbs: false
        }
    ]
};

export default dashboard;
