import config from 'config';

export async function CambiarEstadoVentanilla(datos, uid, estado) {
    if (datos === null || uid === null || estado === '') return;
    await fetch(`${config.server}/queue/estado_ventanilla`, {
        method: 'POST',
        body: JSON.stringify({
            empresa: datos.id_empresa,
            sede: datos.sede,
            uservnt: uid,
            no_ventanilla: datos.ventanilla.toString(),
            estado: estado
        })
    })
        .then((res) => {
            if (res.status !== 200) {
                throw new Error(`El servicio falló con estado ${res.status}`);
            }
        })
        .catch((error) => {
            console.error(error);
        });
}
