import { lazy } from 'react';

// project imports
import MinimalLayout from 'layout/MinimalLayout';
import Loadable from 'ui-component/Loadable';
// login option 3 routing
const Printtk = Loadable(lazy(() => import('views/printtk')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const Simple = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/printtk',
            element: <Printtk />
        }
    ]
};

export default Simple;
