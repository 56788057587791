import { useEffect } from 'react';
import { useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import Simple from './Simple';
import { UseUserState } from 'context/UserContext';
// Services
import { CambiarEstadoVentanilla } from 'services/EstadoVentanilla';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    const { datos, user } = UseUserState();

    useEffect(() => {
        // se ejecuta cuando se cierra la pestaña o el navegador
        window.addEventListener('unload', handleEndVentanilla);
        return () => {
            window.removeEventListener('unload', handleEndVentanilla);
            handleEndVentanilla();
        };
    }, []);

    const handleEndVentanilla = () => {
        CambiarEstadoVentanilla(datos, user.id, 'cerrado');
    };

    return useRoutes([MainRoutes, AuthenticationRoutes]);
}
