import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword, signOut, sendPasswordResetEmail } from 'firebase/auth';
import {
    getDatabase,
    onValue,
    set,
    push,
    update,
    onDisconnect,
    ref,
    serverTimestamp,
    orderByChild,
    equalTo,
    query,
    limitToFirst,
    limitToLast,
    off,
    onChildChanged,
    onChildAdded,
    onChildRemoved,
    startAfter
} from 'firebase/database';
import { getStorage, ref as refStorage, uploadBytesResumable, getDownloadURL, deleteObject } from 'firebase/storage';
import { getMessaging } from 'firebase/messaging';
import { onBackgroundMessage } from 'firebase/messaging/sw';

const config = {
    apiKey: 'AIzaSyDaKZI0TmBCBYTcvI_b5xnkBL78DP1hwpk',
    authDomain: 'trace-513d1.firebaseapp.com',
    databaseURL: 'https://trace-513d1-default-rtdb.firebaseio.com',
    projectId: 'trace-513d1',
    storageBucket: 'trace-513d1.appspot.com',
    messagingSenderId: '87600176904',
    appId: '1:87600176904:web:03a438516f36aa247256bd',
    measurementId: 'G-XZV0P4XL8X'
};

const app = initializeApp(config);

// autenticacion
export const auth = getAuth(app);
export const SignInWithEmailAndPassword = signInWithEmailAndPassword;
export const OnAuthStateChanged = onAuthStateChanged;
export const SignOut = signOut;
export const SendPasswordResetEmail = sendPasswordResetEmail;

// base de datos
export const Basedatos = getDatabase(app);
export const OnValue = onValue;
export const Setdb = set;
export const Updatedb = update;
export const Push = push;
export const OnDisconnect = onDisconnect;
export const Ref = ref;
export const Timestamp = serverTimestamp;
export const OrderByChild = orderByChild;
export const EqualTo = equalTo;
export const Query = query;
export const LimitToFirst = limitToFirst;
export const LimitToLast = limitToLast;
export const Off = off;
export const OnChildChanged = onChildChanged;
export const OnChildAdded = onChildAdded;
export const OnChildRemoved = onChildRemoved;
export const StartAfter = startAfter;

// storage
export const GetStorage = getStorage;
export const RefStorage = refStorage;
export const UploadBytesResumable = uploadBytesResumable;
export const GetDownloadURL = getDownloadURL;
export const DeleteObject = deleteObject;
export const Messaging = getMessaging(app);
export const OnBackgroundMessage = onBackgroundMessage;
