import React, { useEffect } from 'react';
import {
    db,
    auth,
    watchUserChanges,
    watchDatosUSer,
    SignInWithEmailAndPassword,
    SignOut,
    WatchThema,
    SendPasswordResetEmail
} from 'services/firebase';
import { darken, lighten } from '@mui/material/styles';
import { MLog } from 'services/FuncionesDev';
import { CambiarEstadoVentanilla } from 'services/EstadoVentanilla';

const UserStateContext = React.createContext();
const UserDispatchContext = React.createContext();

const temaDefault = {
    secondary: {
        light: '#f9babe',
        main: '#353535',
        dark: '#888686'
    },
    primary: {
        light: '#84252b',
        main: '#e03a44',
        dark: '#84252b'
    },
    colordivider: {
        primary: '#2196f330',
        secondary: '#673ab730'
    },
    text: {
        primaryChat: '#bdc8f0',
        secondaryChat: '#8492c4',
        contrastePrimary: 'white',
        contrasteSecondary: 'white'
    },
    warningMain: '#fff4e5',
    imagenes: {
        fondoChat:
            'https://firebasestorage.googleapis.com/v0/b/avidg-fb168.appspot.com/o/temaLogin%2F275072259_493869745599600_3306070473414927555_n.jpg?alt=media&token=cda3c3b6-ac6d-4b21-82a3-b92b351693c8'
    }
};

function userReducer(state, action) {
    switch (action.type) {
        case 'END_LOAD':
            MLog.print('datos context', action.datos);
            return { ...state, user: action.user, datos: action.datos, authReady: true, isAuthenticated: true };
        case 'LOGIN_SUCCESS':
            return { ...state, isAuthenticated: true };
        case 'SIGN_OUT_SUCCESS':
            return { ...state, isAuthenticated: false, user: null, datos: [], authReady: true };
        case 'LOGIN_FAILURE':
            return { ...state, isAuthenticated: false, user: null, datos: [], authReady: true };
        case 'SET_TEMA':
            return { ...state, temaActual: action.tema, temaReady: action.temaReady };
        /* --------------- Tickets--------------------------- */
        case 'SET_LISTATICKET':
            return { ...state, listaTickets_ventanilla: action.lista };
        case 'ADD_LISTATICKET':
            const indexAdd = state.listaTickets_ventanilla.findIndex((element) => element.key === action.ticket.key);
            if (indexAdd === -1) {
                state.listaTickets_ventanilla.push(action.ticket);
            }
            return { ...state };
        case 'REMOVE_LISTATICKET':
            const index = state.listaTickets_ventanilla.findIndex((element) => element.key === action.ticket.key);
            if (index > -1) {
                state.listaTickets_ventanilla.splice(index, 1);
            }
            return { ...state };
        /*--------------------------sede----------------------------*/
        case 'SET_SEDE':
            return { ...state, sede: action.sede };
        /*---------------------------TRamite ticket----------------------*/
        case 'ADD_TramiteTicket':
            const indexT = state.listaTramitesTicket.findIndex((element) => element.id === action.valor);
            if (indexT !== -1) {
                state.listaTramitesTicket[indexT].cantidad = state.listaTramitesTicket[indexT].cantidad + 1;
                state.contadorlistaTramitesTicket.push({
                    id: action.valor,
                    nombre: state.listaTramitesTicket[indexT].label,
                    acronimo: state.listaTramitesTicket[indexT].acronimo,
                    nombreCompleto: state.listaTramitesTicket[indexT].nombre_completo
                });
            }
            return { ...state };
        case 'DEL_TramiteTicket':
            const indexDT = state.listaTramitesTicket.findIndex((element) => element.id === action.valor);
            if (indexDT !== -1) {
                if (state.listaTramitesTicket[indexDT].cantidad > 0) {
                    state.listaTramitesTicket[indexDT].cantidad = state.listaTramitesTicket[indexDT].cantidad - 1;
                    // eliminar de la lista de datos a enviar
                    const indexC = state.contadorlistaTramitesTicket.findIndex((element) => element.id === action.valor);
                    if (indexC !== -1) {
                        state.contadorlistaTramitesTicket.splice(indexC, 1);
                    }
                }
            }
            return { ...state };
        case 'SET_ListaTramiteTicket':
            return { ...state, listaTramitesTicket: action.lista, contadorlistaTramitesTicket: [] };
        /*----------------------------------------AGREGAR Tramite a la lista---------------------------------------*/
        case 'ADD_ListaTramiteTicket':
            state.listaTramitesTicket.push(action.tramite);
            MLog.print('datos listaTramitesTicket', state.listaTramitesTicket);
            MLog.print('datos context', action.tramite);
            return { ...state };
        default: {
            throw new Error(`Unhandled action type: ${action.type}`);
        }
    }
}

function UserProvider({ children }) {
    const [state, dispatch] = React.useReducer(userReducer, {
        isAuthenticated: false,
        user: null, // son los datos del usuario obtenidos de firebase ath
        datos: [],
        temaReady: false,
        authReady: false,
        temaActual: temaDefault,
        listaTickets_ventanilla: [],
        listaAtencion: [],
        sede: null,
        listaTramitesTicket: [],
        contadorlistaTramitesTicket: []
    });

    useEffect(() => {
        WatchThema('123456', (tema) => {
            if (tema != null) {
                const actual = {
                    secondary: {
                        light: lighten(tema.secondary, 0.5),
                        main: tema.secondary,
                        dark: darken(tema.secondary, 0.15)
                    },
                    primary: {
                        light: lighten(tema.primary, 0.5),
                        main: tema.primary,
                        dark: darken(tema.primary, 0.15)
                    },
                    colordivider: {
                        primary: '#2196f330',
                        secondary: '#673ab730'
                    },
                    text: {
                        primaryChat: tema.text.primaryChat,
                        secondaryChat: tema.text.secondaryChat,
                        contrastePrimary: tema.text.contrastePrimary,
                        contrasteSecondary: tema.text.contrasteSecondary
                    },
                    imagenes: {
                        fondoChat: tema.imagenes.fondoChat
                    },
                    warningMain: '#fff4e5'
                };
                dispatch({ type: 'SET_TEMA', tema: actual, temaReady: true });
            } else {
                dispatch({ type: 'SET_TEMA', tema: temaDefault, temaReady: true });
            }
        });
        watchUserChanges((user) => {
            if (user) {
                watchDatosUSer(user.id, (datos) => {
                    MLog.print('datos del user cambiaron', datos);
                    if (!datos.en_linea) {
                        dispatch({ type: 'END_LOAD', user, datos });
                        CambiarEstadoVentanilla(datos, user.id, 'disponible');
                    }
                });
            } else {
                dispatch({ type: 'LOGIN_FAILURE' });
            }
        });
    }, []);

    return (
        <UserStateContext.Provider value={state}>
            <UserDispatchContext.Provider value={dispatch}>{children}</UserDispatchContext.Provider>
        </UserStateContext.Provider>
    );
}

function UseUserState() {
    const context = React.useContext(UserStateContext);
    if (context === undefined) {
        throw new Error('useUserState must be used within a UserProvider');
    }
    return context;
}

function UseUserDispatch() {
    const context = React.useContext(UserDispatchContext);
    if (context === undefined) {
        throw new Error('useUserDispatch must be used within a UserProvider');
    }
    return context;
}

// ###########################################################
function LoginUser(dispatch, correo, password, setError, setErrordetail) {
    MLog.print('login');
    if (!!correo && password) {
        SignInWithEmailAndPassword(auth, correo, password)
            .then((userCredential) => {
                const user = userCredential.user;
                MLog.print('user', user);
                if (user.emailVerified) {
                    MLog.print('Email verificado');
                } else {
                    MLog.print('Email no verificado');
                }
            })
            .catch((error) => {
                // setErrordetail(error.message);
                // setErrordetail("error")
                // auth/user-not-found error.code "el usuario no existe"
                // auth/wrong-password password incorrecto
                // auth/too-many-requests se supero el limite de intentso
                if (error.code === 'auth/user-not-found') {
                    setErrordetail({ submit: 'El usuario ingresado no es valido' });
                } else if (error.code === 'auth/wrong-password') {
                    setErrordetail({ submit: 'credenciales incorrectas' });
                } else if (error.code === 'auth/too-many-requests') {
                    setErrordetail({ submit: 'Se supero el limite de intentos, por favor intente mas tarde' });
                } else {
                    setErrordetail({ submit: 'Ocurrio un error, por favor intente de nuevo' });
                }
                setError({ success: false });

                // console.error('Error signing in with password and email', error.message);
                // console.error('Error codigo error', error.code);
            });
    } else {
        MLog.print('datos incompletos');
    }
}

function Logaout(dispatch) {
    MLog.print('logOut', auth);
    SignOut(auth)
        .then(() => {
            dispatch({ type: 'SIGN_OUT_SUCCESS' });
            MLog.print('singOut correcto');
        })
        .catch((error) => {
            MLog.print('error logOut');
        });
}

function UserForgetPassword(correo, setStatus, setErrors, setSubmitting, setSuccsesForget) {
    MLog.print('userForgetPassword');
    SendPasswordResetEmail(auth, correo)
        .then(() => {
            setSuccsesForget(true);
            setStatus({ success: true });
            setErrors({ submit: null });
            setSubmitting(false);
        })
        .catch((error) => {
            setStatus({ success: false });
            setErrors({ submit: 'Error al restaurar el password' });
            setSubmitting(false);
        });
}

function UserNewPassword(password, setIsLoading, setError, setErrordetail, setSuccsesNewPassword) {
    MLog.print('New password');
}

function AgregarListaTickets(dispatch, lista) {
    dispatch({ type: 'SET_LISTATICKET', lista: lista });
}

function AgregarTicket(dispatch, ticket) {
    dispatch({ type: 'ADD_LISTATICKET', ticket: ticket });
}

function EliminarTicket(dispatch, ticket) {
    dispatch({ type: 'REMOVE_LISTATICKET', ticket: ticket });
}

function SetSede(dispatch, sede) {
    dispatch({ type: 'SET_SEDE', sede: sede });
}

function SetTramitesTicket(dispatch, valor) {
    dispatch({ type: 'ADD_TramiteTicket', valor: valor });
}

function SetListaTramitesTicket(dispatch, lista) {
    dispatch({ type: 'SET_ListaTramiteTicket', lista: lista });
}

function SetADDListaTramitesTicket(dispatch, tramite) {
    dispatch({ type: 'ADD_ListaTramiteTicket', tramite: tramite });
}

function DelTramitesTicket(dispatch, valor) {
    dispatch({ type: 'DEL_TramiteTicket', valor: valor });
}
/* ------------------------------- Bandejas ------------------------------------------------ */
export { UserProvider, UseUserState, UseUserDispatch, LoginUser, Logaout, UserForgetPassword, UserNewPassword };
export { AgregarListaTickets, AgregarTicket, EliminarTicket };
export { SetSede };
export { SetTramitesTicket, SetListaTramitesTicket, SetADDListaTramitesTicket, DelTramitesTicket };
