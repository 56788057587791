import dashboard from './dashboard';
import pages from './pages';
import utilities from './utilities';
import admin from './admin';
import other from './other';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    // items: [dashboard, admin]
    items: [dashboard]
};

export default menuItems;
